import React from "react"
import { Helmet } from "react-helmet"
import { graphql, useStaticQuery } from "gatsby"

const Head = props => {
  const { title } = props
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)
  return (
    <Helmet>
      <html lang="en" />
      <title>{`${title ? title + " | " : ""}${
        data.site.siteMetadata.title
      }`}</title>
      <meta name="description" content={data.site.siteMetadata.description} />
    </Helmet>
  )
}

export default Head
